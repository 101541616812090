// AuthContext.tsx

import React, { useState, useContext, createContext, Dispatch, SetStateAction } from "react";

interface AuthContextProps {
    token: string | null;
    setToken: Dispatch<SetStateAction<string | null>>;
    setTokenCallback: (newToken: string | null, callback: () => void) => void;
}

const AuthContext = createContext<AuthContextProps>({
    token: null,
    setToken: () => { },
    setTokenCallback: () => { },
});

export const useAuth = () => useContext(AuthContext);
type AuthProviderProps = {
    children: React.ReactNode;
};
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | null>(null);

    const setTokenCallback = (newToken: string | null, callback: () => void) => {
        setToken(newToken);
        callback();
    };

    return (
        <AuthContext.Provider value={{ token, setToken, setTokenCallback }}>
            {children}
        </AuthContext.Provider>
    );
};
