// App.tsx

import React, { useEffect, useState } from "react";
import axios from "axios";

import logo from "./logo.svg";
import "./App.css";
import UTMGenerator from "./UTMGenerator";
// import Home from "./Home";
import SignInScreen from "./SignInScreen"; // Import the SignInScreen component
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from './AuthContext'; // Import the useAuth hook
import { AuthProvider } from './AuthContext'; // Import the AuthProvider component

interface UserProfile {
    id: string;
    email: string;
    name: string;
    group: string;
    allowed_domain: string;
}

export const NotFoundRedirect = () => {
    useEffect(() => {
        window.location.href = "/custom_404.html";
    }, []);

    return null;
};

const HomePage = ({ user }: { user: string }) => {
    return (
        <div className="App">
            <UTMGenerator user={user} />
        </div>
    );
};

function App() {
    const [userId, setUserId] = useState("");
    const { token, setToken } = useAuth();
    // const storedToken = localStorage.getItem("token");

    useEffect(() => {
        const storedToken = localStorage.getItem("token");

        if (storedToken) {
            setToken(storedToken); // Update the token in the AuthProvider

            const fetchData = async () => {
                const response = await axios.get<UserProfile>("/api/v1/auth/user/me", { headers: { "xc-auth": storedToken } });
                const usernick = response.data.email && response.data.email.split("@")[0]
                setUserId(usernick);
            };

            fetchData();
        }
    }, [setToken, token]);

    if (userId === null) {
        return <div>Loading...</div>; // or return null or some loading component
    }

    return (
        <Router>
            <Routes>
                {token ? (
                    // If the user is authenticated
                    <>
                        <Route path="/" element={<HomePage user={userId} />} />
                        <Route path="/signin" element={<Navigate to="/" />} />
                        {/* <Route path="/home" element={<Home user={userId} />} /> */}
                    </>
                ) : (
                    // If the user is not authenticated
                    <>
                        <Route path="/" element={<Navigate to="/signin" />} />
                        <Route path="/signin" element={<SignInScreen />} />
                    </>
                )}
                <Route path="*" element={<NotFoundRedirect />} />
            </Routes>
        </Router>

    );
}

export default App;
