import React, { useEffect, useState } from "react";
import axios from "axios";
// import { Button, TextField, Container, Typography, Box, Grid } from "@mui/material";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Container,
    Typography,
    Grid,
    Box,
    SelectChangeEvent,
    IconButton,
    Input,
    FormHelperText,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import CssBaseline from "@mui/material/CssBaseline";
import CakeSharpIcon from "@mui/icons-material/CakeSharp";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Chip from "@mui/material/Chip";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Api } from "nocodb-sdk";
import moment from "moment-timezone";
import EditableAutoSizedTable from "./EditableAutoSizedTable";
import Swal from "sweetalert2";
import Papa, { ParseResult } from "papaparse";
import { useNavigate } from "react-router-dom";
import SignOutButton from "./SignOutButton";

//TODO: 컴펙트하게 보이도록 입력 필드들 재 배치
// const burl = "http://better.cleanb.life:30008";
const burl = "https://nocodb.cleanb.life";
// const burl = "https://better.cleanb.life";
const authToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImRhbWlhbm9zcGFya0BnbWFpbC5jb20iLCJmaXJzdG5hbWUiOm51bGwsImxhc3RuYW1lIjpudWxsLCJpZCI6InVzX21oNThvb21wa2d6eXZrIiwicm9sZXMiOiJvcmctbGV2ZWwtY3JlYXRvcixzdXBlciIsInRva2VuX3ZlcnNpb24iOiIxZDk1N2ZjNzBiYzY5OWExYmQzNjAwOWVlNWRkM2UzNWFhNDQzYzEwOTFjN2QwOTgwZGQ2YjcwZWMyZDg5MWM3YjNhMTJjMTMwMzdiYTdkMiIsImlhdCI6MTY4MDI4MzA2MSwiZXhwIjoxNjgwMzE5MDYxfQ.Gfe-wEjy-Pdw93TlSrqkW1XB7Y9SXnzi3_fIYRLL5Z0";

const api = new Api({
    baseURL: burl,
    headers: {
        "xc-auth": authToken,
        "xc-token": "xMoblTjgDLRMNST0mo86U1c8p6LLiiqn17GyiyQ8",
    },
});

interface UTMParams {
    land_url: string;
    source: string;
    medium: string;
    campaign: string;
    term?: string;
    content?: string;
    ad_name: string;
    caption: string;
    description: string;
}

moment.locale("ko");
function toKSTISOString(date: Date): string {
    return moment(date).tz("Asia/Seoul").toISOString();
}
const sources = ["facebook", "instagram", "naver", "google", "daangn", "incruit", "jobkorea"];
const mediums = ["social", "blog", "search", "naver_power_ranking", "naver_shop_search", "naver_cafe", "influencer", "bulletin"];
const filter = createFilterOptions<string>();
const landing_url = "";
// const landing_url = "https://www.cleanbedding.kr/product/trial";

interface UTMGeneratorProps {
    user: string;
}

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}

function HideOnScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const UTMGenerator: React.FC<UTMGeneratorProps> = (props) => {
    const [rowId, setRowId] = useState(-1);
    const [utmParams, setUtmParams] = useState<UTMParams>({
        source: "",
        medium: "",
        campaign: "",
        term: "",
        content: "",
        land_url: "",
        ad_name: "",
        caption: "",
        description: "",
    });
    const [generatedUrl, setGeneratedUrl] = useState("");

    const [openDialog, setOpenDialog] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [missingFields, setMissingFields] = useState<string[]>([]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const response = await axios.get<UserProfile>("/api/v1/auth/user/me", { headers: { "xc-auth": token } });
    //         setProfile(response.data);
    //     };

    //     fetchData();
    // }, [token]); // Add 'token' to dependency array to re-run the effect when 'token' changes

    const validateForm = () => {
        const requiredFields = ["land_url", "source", "medium", "campaign", "content"];
        const missing = requiredFields.filter((field) => !utmParams[field as keyof UTMParams]);
        setMissingFields(missing);
        return missing.length === 0;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>, param: keyof UTMParams) => {
        setUtmParams({ ...utmParams, [param]: e.target.value });
    };

    const handleChangeAutoComplete = (newValue: string | null, param: keyof UTMParams) => {
        if (newValue) {
            if (newValue.includes("Add ")) {
                newValue = newValue.replace("Add ", "").replaceAll('"', "");
            }
            console.log(param, newValue);
            setUtmParams({ ...utmParams, [param]: newValue });
        } else {
            setUtmParams({ ...utmParams, [param]: "" });
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCloseUploadModal = () => {
        setOpenUploadModal(false);
    };

    const resetValues = () => {
        setUtmParams({
            ...utmParams,
            source: "",
            medium: "",
            campaign: "",
            term: "",
            content: "",
            land_url: "",
            ad_name: "",
            caption: "",
            description: "",
        });
    };

    const generateUTM = async () => {
        // const now: Date = moment(new Date()).toDate();
        // const twoWeeksFromNow: Date = moment(new Date()).add(2, "weeks").toDate();

        const now: string = moment(new Date()).format("YYYY-MM-DD");
        const twoWeeksFromNow: string = moment(new Date()).add(2, "weeks").format("YYYY-MM-DD");
        console.log("moment -> ", typeof now, now);
        // console.log("moment -> ", now, );
        // console.log(utmParams);
        if (!validateForm()) {
            setOpenDialog(true);
            return;
        }
        const filtering = ['land_url', 'ad_name', 'caption', 'description'];
        const encodedParams = Object.entries(utmParams)
            // .filter(([_, value]) => value)
            // .filter(([key, value]) => key !== "land_url" && value)
            .filter(([key, value]) => !filtering.includes(key) && value)
            .map(([key, value]) => `utm_${key}=${encodeURIComponent(value.trim().replace(/\s+/g, "-"))}`)
            .join("&");
        const utm = `${utmParams.land_url}?${encodedParams}`;
        setGeneratedUrl(utm);

        await api.dbTableRow
            .create("noco", "UtmGenerator", "utm_data", {
                // Id: 0,
                // source: utmParams.source,
                // medium: utmParams.medium,
                // campaign: utmParams.campaign,
                // Term: utmParams.term,
                // content: "string",
                ...utmParams,
                utm: utm,
                post_url: "string",
                descr: "string",
                // created_at: moment(new Date()),
                // UpdatedAt: null,
                creator_id: props.user,
                updater_id: props.user,
                budget: 0,
                currency: "원",
                start_date: now,
                end_date: twoWeeksFromNow,
                target_location: null,
                target_audience: null,
            })
            .then(function (data) {
                setRowId(data.id);
                console.log("then data", data);
                resetValues();
            })
            .catch(function (error) {
                // console.error("---error---", error);
                // console.error(error.response.data);
                console.error(error.response.data.info, error.response.data.message);
                console.error(error.response.data.info.message);
                alert(`데이터 삽입 오류가 발생했습니다.\n CODE:${error.response.data.info.code}\n MESSAGE: ${error.response.data.message}`);
            });
    };

    const ALLOW_FILE_EXTENSION = "csv, xlsx";

    const convertFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        //console.log(e);
        const target = e.currentTarget;
        const files = (target.files as FileList)[0];
        console.log(files);

        if (files === undefined) {
            return;
        }

        if (!fileExtensionValid(files)) {
            handleCloseUploadModal();
            target.value = "";
            Swal.fire({
                icon: "error",
                title: "파일 확장자를 확인하세요.",
                text: `업로드 가능한 확장자: ${ALLOW_FILE_EXTENSION} `,
            });
        } else {
            Papa.parse(files, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    //console.log('Finished', results.data);
                    confirmCSVFileUploadData(results.data);
                },
            });
        }
    };

    const fileExtensionValid = ({ name }: { name: string }): boolean => {
        const extension = removeFileName(name);
        if (!(ALLOW_FILE_EXTENSION.indexOf(extension) > -1) || extension === "") {
            return false;
        }
        return true;
    };

    const removeFileName = (originalFileName: string): string => {
        const lastIndex = originalFileName.lastIndexOf(".");

        if (lastIndex < 0) {
            return "";
        }

        return originalFileName.substring(lastIndex + 1).toLowerCase();
    };

    const confirmCSVFileUploadData = (datas: any) => {
        console.log("datas", datas);
        for (let ii = 0; ii < datas.length; ++ii) {
            let source = datas[ii].source;
            let medium = datas[ii].medium;
            let campaign = datas[ii].campaign;
            let content = datas[ii].content;
            let url = datas[ii].url;
            if (source === "" || medium === "" || campaign === "" || content === "" || url === "") {
                handleCloseUploadModal();
                Swal.fire({
                    icon: "error",
                    title: "term항목을 제외한 모든 항목은 필수입니다.",
                    text: `수정해야할 행: ${ii + 2} `,
                });
                return;
            }
        }

        handleCloseUploadModal();
        Swal.fire({
            icon: "question",
            text: `${datas.length}행의 UTM데이터를 등록하시겠습니까?`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "등록",
            cancelButtonText: "취소",
        }).then((result) => {
            if (result.isConfirmed) {
                saveCSVFileUploadData(datas);
            } else {
                Swal.fire("취소되었습니다.", "", "info");
            }
        });
    };

    const saveCSVFileUploadData = async (datas: any) => {
        const now: string = moment(new Date()).format("YYYY-MM-DD");
        const twoWeeksFromNow: string = moment(new Date()).add(2, "weeks").format("YYYY-MM-DD");
        let s_count = 0;
        let e_count = 0;
        for (let ii = 0; ii < datas.length; ++ii) {
            const utmParams = {
                source: datas[ii].source,
                medium: datas[ii].medium,
                campaign: datas[ii].campaign,
                term: datas[ii].term,
                content: datas[ii].content,
                land_url: datas[ii].url,
                ad_name: datas[ii].ad_name,
                caption: datas[ii].caption,
                description: datas[ii].description,
            };
            const encodedParams = Object.entries(utmParams)
                .filter(([key, value]) => key !== "land_url" && value)
                .map(([key, value]) => `utm_${key}=${encodeURIComponent(value.trim().replace(/\s+/g, "-"))}`)
                .join("&");
            console.log("encodedParams", encodedParams);
            const utm = `${utmParams.land_url}?${encodedParams}`;
            console.log("utm", utm);
            await api.dbTableRow
                .create("noco", "UtmGenerator", "utm_data", {
                    ...utmParams,
                    utm: utm,
                    post_url: "string",
                    descr: "string",
                    creator_id: props.user,
                    updater_id: props.user,
                    budget: 0,
                    currency: "원",
                    start_date: now,
                    end_date: twoWeeksFromNow,
                    target_location: null,
                    target_audience: null,
                })
                .then(function (data) {
                    setRowId(data.id);
                    console.log("then data", data);
                    s_count += 1;
                })
                .catch(function (error) {
                    e_count += 1;
                });
        }
        console.log(s_count);
        console.log(e_count);
        if (s_count === datas.length) {
            Swal.fire({
                icon: "success",
                title: "성공적으로 등록 되었습니다.",
                text: `성공건수: ${s_count}`,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "데이터 삽입 오류가 발생했습니다.",
                text: `성공건수: ${s_count} \n오류건수: ${e_count}`,
            });
        }
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <HideOnScroll {...props}>
                <AppBar>
                    <Toolbar>
                        <Chip label={props.user} color="warning" size="medium" />
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} gutterBottom>
                            Cleanbedding UTM 생성기
                        </Typography>
                        <SignOutButton />
                        {/* <IconButton
                            size="large"
                            aria-label="UTM track result"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => alert("성과 확인 기능 추가 예정입니다.")}
                            color="inherit"
                        >

                            <CakeSharpIcon />
                        </IconButton> */}
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar />
            <div className="page-wraper">
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Grid mb={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setOpenUploadModal(true);
                            }}
                            endIcon={<UploadFileIcon />}
                        >
                            Upload CSV
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <Container sx={{ minWidth: "100%", paddingX: 0, paddingY: 2 }}>
                <Box mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                focused
                                color="secondary"
                                label="랜딩페이지 URL"
                                variant="outlined"
                                fullWidth
                                // value={landing_url}
                                // onChange={(e) => setUrl(e.target.value)}
                                onChange={(e) => handleChange(e, "land_url")}
                            />
                            <FormHelperText>랜딩페이지의 url입력 (기본 값과 다를 경우 입력)</FormHelperText>
                        </Grid>
                        {/* <Grid item xs={7}>
                            <Typography variant="body1" color="textSecondary" gutterBottom align="left">
                                랜딩페이지의 url입력 (기본 값과 다를 경우 입력)
                            </Typography>
                        </Grid> */}
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Autocomplete
                                options={sources}
                                getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                        return option;
                                    }
                                    // Regular option
                                    return "";
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option);
                                    if (inputValue !== "" && !isExisting) {
                                        filtered.push(`Add "${inputValue}"`); //inputValue,
                                    }

                                    return filtered;
                                }}
                                freeSolo
                                selectOnFocus
                                value={utmParams.source}
                                onChange={(e, newValue) => handleChangeAutoComplete(newValue, "source")}
                                renderInput={(params) => (
                                    <div>
                                        {" "}
                                        <TextField {...params} required focused color="secondary" label="Source" variant="outlined" fullWidth />{" "}
                                    </div>
                                )}
                            />
                            <FormHelperText>광고 또는 이벤트 소스</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                options={mediums}
                                getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                        return option;
                                    }
                                    // Regular option
                                    return "";
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option);
                                    if (inputValue !== "" && !isExisting) {
                                        filtered.push(`Add "${inputValue}"`); //inputValue,
                                    }

                                    return filtered;
                                }}
                                freeSolo
                                selectOnFocus
                                value={utmParams.medium}
                                onChange={(e, newValue) => handleChangeAutoComplete(newValue, "medium")}
                                renderInput={(params) => (
                                    <div>
                                        {" "}
                                        <TextField {...params} required focused color="secondary" label="Medium" variant="outlined" fullWidth />{" "}
                                    </div>
                                )}
                            />
                            <FormHelperText>링크가 게시되는 매체의 종류</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                required
                                focused
                                color="secondary"
                                label="Campaign"
                                variant="outlined"
                                fullWidth
                                value={utmParams.campaign}
                                onChange={(e) => handleChange(e, "campaign")}
                                placeholder={"ex. 가을신상, 여름대전, ...."}
                            />
                            <FormHelperText>한글로 된 캠페인 이름</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                required
                                focused
                                color="secondary"
                                label="Content"
                                variant="outlined"
                                fullWidth
                                value={utmParams.content}
                                onChange={(e) => handleChange(e, "content")}
                                placeholder={"ex. 20대, 하남, 블로거, 카페아이디 ..."}
                            />
                            <FormHelperText>타게팅하는 고객군</FormHelperText>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                            <TextField
                                focused
                                label="Term (optional)"
                                variant="outlined"
                                fullWidth
                                value={utmParams.term}
                                onChange={(e) => handleChange(e, "term")}
                                placeholder={"(블로그등의 게시글일 경우 컨텐츠 입력하여 자동 생성)"}
                            />
                            <FormHelperText>광고 컨텐츠의 키워드- 해시태그, SEO키워드, 광고키워드</FormHelperText>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                focused
                                label="광고이름 (optional)"
                                variant="outlined"
                                fullWidth
                                value={utmParams.ad_name}
                                onChange={(e) => handleChange(e, "ad_name")}
                                placeholder={"ex. 청록색 배경 단일 (영상화 이미지)"}
                            />
                            <FormHelperText>광고에 사용된 미디어 컨텐츠 이름 등</FormHelperText>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Grid item xs={12}>
                        <TextField
                            focused
                            label="광고제목 (optional)"
                            variant="outlined"
                            fullWidth
                            value={utmParams.caption}
                            onChange={(e) => handleChange(e, "caption")}
                            placeholder={"ex. 꿉꿉한 장마철엔 이불 빨래 걱정 없게끔! 퇴근 후 집에 오면 뽀송한 호텔침구가 도착해있어요."}
                        />
                        <FormHelperText>광고 카피 제목 등</FormHelperText>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Grid item xs={12}>
                        <TextField
                            focused
                            label="설명 (optional)"
                            variant="outlined"
                            fullWidth
                            value={utmParams.description}
                            onChange={(e) => handleChange(e, "description")}
                            placeholder={
                                "ex. 비오는 주말엔 이불빨래 하지 마세요! 클린베딩이 원하는 주기마다 5성급 호텔 침구를 문 앞으로 배송해드려요."
                            }
                        />
                        <FormHelperText>광고에 대한 설명</FormHelperText>
                    </Grid>
                </Box>

                <Box mb={2}>
                    <Button variant="contained" color="primary" onClick={generateUTM}>
                        생성
                    </Button>
                </Box>
                {generatedUrl && (
                    <Box>
                        <Typography variant="h6">생성된 UTM 링크</Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={generatedUrl}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    navigator.clipboard.writeText(generatedUrl);
                                }}
                            >
                                복사
                            </Button>
                        </Box>
                    </Box>
                )}
                {/* <EditableAutoSizedTable tableName="utm_data" authToken={authToken} burl={burl} createdId=/> */}
                <EditableAutoSizedTable client={api} createdId={rowId} />
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>Missing Fields</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            다음의 필드들은 필수 값입니다.
                            <ul>
                                {missingFields.map((field) => (
                                    <li key={field}>{field}</li>
                                ))}
                            </ul>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openUploadModal} onClose={handleCloseUploadModal}>
                    <DialogTitle>CSV File Upload</DialogTitle>
                    <DialogContent>
                        <DialogContentText>CSV파일을 업로드 해주세요.</DialogContentText>
                        <Input type={"file"} id={"fileInput"} onChange={convertFile} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseUploadModal} color="primary">
                            닫기
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </React.Fragment>
    );
};

export default UTMGenerator;
