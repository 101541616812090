import { Button } from "@material-ui/core";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext'; // Import the useAuth hook

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const SignOutButton: React.FC = () => {
    const { setToken } = useAuth(); // Get the setToken function from the AuthContext
    const navigate = useNavigate();

    const handleSignOut = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("No token found");
            }

            const response = await axios.post("/api/v1/auth/user/signout", null, { headers: { "xc-auth": token } });
            // handle response
            localStorage.removeItem("token");
            console.log('navigate? -----------------------')
            setToken(null);
            navigate('/signin')

        } catch (error) {
            console.log('-----------------------')
            console.error(error);
            console.log('-----------------------')
        }
    };

    return (
        <Button variant="contained" color="secondary" onClick={handleSignOut}>
            Sign Out
        </Button>
    );
};

export default SignOutButton;
